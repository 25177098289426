import { Box, Stack, Typography } from '@mui/material';
import { AccountUser, useUpdateUser } from 'api/users';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import {
  convertToRolesArray,
  getRoleCheckboxValues,
  rolesFormResolver,
  UserRoleCheckboxes,
} from 'utils/user';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';
import { CheckboxField } from 'component/new_design/base/forms/CheckboxField';

interface UserRoleDialogProps {
  readonly user: AccountUser;
  readonly onClose: () => void;
}

export const UserRoleDialog = ({ user, onClose }: UserRoleDialogProps) => {
  const { t } = useTranslation();
  const updateUser = useUpdateUser();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<UserRoleCheckboxes>({
    defaultValues: getRoleCheckboxValues(user.roles),
    resolver: rolesFormResolver,
  });

  const handleSubmit = async (data: UserRoleCheckboxes) => {
    await updateUser.mutateAsync({
      userId: user.id!,
      data: { roles: convertToRolesArray(data) },
    });

    enqueueSnackbar(t('roles_changed_success'), {
      key: 'changeRoleSuccess',
      variant: 'success',
    });

    onClose();
  };

  return (
    <FormDialog
      methods={methods}
      open
      onSubmit={handleSubmit}
      form={
        <Stack gap={4}>
          <Box>
            <CheckboxField name="role_account_user" label={t('account_user')} />
            <Typography variant="body2">{t('account_user_description')}</Typography>
          </Box>
          <Box>
            <CheckboxField name="role_billing_admin" label={t('billing_admin')} />
            <Typography variant="body2">{t('billing_admin_description')}</Typography>
          </Box>
          <Box>
            <CheckboxField name="role_user_admin" label={t('user_admin')} />
            <Typography variant="body2">{t('user_admin_description')}</Typography>
          </Box>
        </Stack>
      }
      submitText={t('change')}
      onClose={onClose}
      title={t('change_user_role')}
    />
  );
};
