import { useId, useState } from 'react';
import Plus from '../icons/plus.svg?react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../base/dialogs/FormDialog';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TextField } from '../base/forms/TextField';
import { useInviteUser } from 'api/users';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { components } from 'openapi-types';
import { Label } from '../base/forms/Label';
import { CheckboxField } from '../base/forms/CheckboxField';
import {
  convertToRolesArray,
  rolesDefaultValues,
  rolesFormResolver,
  UserRoleCheckboxes,
} from 'utils/user';
import { SwitchField } from '../base/forms/SwitchField';

type InviteUserFormData = Omit<components['schemas']['CreateUserRequest'], 'roles'> &
  UserRoleCheckboxes;

export default function InviteUser() {
  const { t } = useTranslation();
  const { mutateAsync } = useInviteUser();
  const { enqueueSnackbar } = useSnackbar();
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const methods = useForm<InviteUserFormData>({
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      twofactor_required: false,
      ...rolesDefaultValues,
    },
    resolver: rolesFormResolver,
  });

  const rolesLabelId = useId();

  function handleClose() {
    methods.reset();
    setShowAddDialog(false);
  }

  const onSubmit = async (data: InviteUserFormData) => {
    await mutateAsync({ ...data, roles: convertToRolesArray(data) });

    enqueueSnackbar(t('user_invited_successfully'), {
      key: 'inviteUser',
      variant: 'success',
    });

    handleClose();
  };

  const twofaRequiredId = `2fa_required_${useId()}`;

  return (
    <>
      <FormDialog
        form={
          <Stack spacing={4} direction="column">
            <Typography variant="body2" color="greys.500">
              {t('invite_user_details')}
            </Typography>
            <TextField
              name="firstname"
              label={t('first_name')}
              fullWidth
              rules={{ required: true }}
            />
            <TextField
              name="lastname"
              label={t('last_name')}
              fullWidth
              rules={{ required: true }}
            />
            <TextField name="email" fullWidth label={t('email')} rules={{ required: true }} />
            <Stack gap={4}>
              <Stack role="group" gap={3} aria-labelledby={rolesLabelId}>
                <Label id={rolesLabelId}>{t('roles')}</Label>
                <Box>
                  <CheckboxField name="role_account_user" label={t('account_user')} />
                  <Typography variant="body2" color="greys.500">
                    {t('account_user_description')}
                  </Typography>
                </Box>
                <Box>
                  <CheckboxField name="role_billing_admin" label={t('billing_admin')} />
                  <Typography variant="body2" color="greys.500">
                    {t('billing_admin_description')}
                  </Typography>
                </Box>
                <Box>
                  <CheckboxField name="role_user_admin" label={t('user_admin')} />
                  <Typography variant="body2" color="greys.500">
                    {t('user_admin_description')}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" gap={2}>
                <SwitchField name="twofactor_required" aria-labelledby={twofaRequiredId} />
                <Stack gap={1}>
                  <Typography
                    variant="body2"
                    color="greys.900"
                    fontWeight="700"
                    id={twofaRequiredId}
                  >
                    {t('force_2factor_auth')}
                  </Typography>
                  <Typography variant="body2" color="greys.500">
                    {t('force_2factor_auth_warning')}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        }
        methods={methods}
        onSubmit={onSubmit}
        onClose={handleClose}
        open={showAddDialog}
        title={t('invite_user')}
        submitText={t('invite')}
      />
      <Button
        onClick={() => {
          setShowAddDialog(true);
        }}
        size="medium"
        variant="contained"
        color="primary"
        startIcon={<Plus />}
      >
        {t('invite_user')}
      </Button>
    </>
  );
}
