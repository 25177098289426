import { useState } from 'react';
import { Chip, ChipProps } from '@mui/material';
import { SiteDetail } from 'api/site';
import { ConfirmationDialog } from '../base/dialogs/ConfirmationDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Table } from 'component/new_design/base/Table';
import { TableColumnType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EmptyState } from '../base/EmptyState';
import InviteUser from './InviteUser';
import {
  useDeleteUser,
  useReinviteUser,
  useUserChangeTwoAuth,
  useGetAccountUsersPaginated,
  AccountUser,
} from 'api/users';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { YesNoCell } from './tables/YesNoCell';

// icons
import UserThree from '../icons/UserThree.svg?react';
import LockClosed from '../icons/LockClose.svg?react';
import User from '../icons/User.svg?react';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import { UserRoleDialog } from './Settings/Users/UserRoleDialog';

function NameCell({ user }: { readonly user: AccountUser }) {
  if (user.firstname && user.lastname) {
    return `${user.firstname} ${user.lastname}`;
  }
  return user.firstname ?? user.lastname;
}

function TwoFACell({ user }: { readonly user: AccountUser }) {
  const { t } = useTranslation();
  return user.twofactor_enabled ? t('enabled') : t('disabled');
}

export default function UserList() {
  const [userToDelete, setUserToDelete] = useState<AccountUser | null>(null);
  const [userToChangeRole, setUserToChangeRole] = useState<AccountUser | null>(null);
  const tableState = usePaginatedListState();
  const { mutateAsync: deleteAccountUser } = useDeleteUser();
  const { mutateAsync: reinviteUser } = useReinviteUser();
  const { mutateAsync: changeTwoFactorAuth } = useUserChangeTwoAuth();
  const { isLoading, data } = useGetAccountUsersPaginated(tableState);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const columns: TableColumnType<AccountUser>[] = [
    {
      label: t('name'),
      key: 'firstname',
      renderValue: user => <NameCell user={user} />,
      sortable: true,
      width: 1.5,
    },
    {
      label: t('email'),
      key: 'email',
      sortable: true,
      width: 2,
    },
    {
      label: t('2fa'),
      key: 'twofactor_enabled',
      renderValue: user => <TwoFACell user={user} />,
      sortable: false,
      width: 1,
    },
    {
      label: t('2fa_required'),
      key: 'twofactor_required',
      renderValue: user => <YesNoCell value={user.twofactor_required} />,
      sortable: false,
      width: 1,
    },
    {
      label: t('status'),
      key: 'status',
      renderValue: user => {
        if (!user.status) {
          return '';
        }

        let color: ChipProps['color'] = 'success';

        if (user.status === 'invited') {
          color = 'warning';
        }

        return <Chip variant="tag" label={t(`statuses.${user.status}`)} color={color} />;
      },
      sortable: true,
      width: 0.5,
    },
    {
      label: t('roles'),
      key: 'roles',
      renderValue: user => {
        return user.roles?.map(role => t(role)).join(', ');
      },
      sortable: false,
      width: 2,
    },
  ];

  const handleConfirmDelete = async () => {
    if (!userToDelete || !userToDelete.id) {
      throw new Error('User ID is missing');
    }

    await deleteAccountUser({ userId: userToDelete.id });
    enqueueSnackbar(t('user_removed_successfully'), {
      key: 'RemoveUser',
      variant: 'success',
    });
    setUserToDelete(null);
  };

  return (
    <>
      <Table
        title={t('users')}
        label={t('users_table')}
        actionColumnOverrides={{
          minWidth: '130px',
        }}
        rowActions={[
          {
            onClick: async user => {
              await reinviteUser({ userId: user.id ?? '' });
              enqueueSnackbar(t('invite_resent_successfully'), {
                key: 'InviteUser',
              });
            },
            inline: true,
            label: t('resend'),
            hidden: user => user.status === 'active',
          },
          {
            onClick: async user => {
              await changeTwoFactorAuth({ userId: user.id ?? '', twofa: true });
              enqueueSnackbar(t('two_factor_authentication_enabled'), {
                key: 'twoFactorAuth',
                variant: 'success',
              });
            },
            icon: <LockClosed />,
            label: t('force_2factor_auth'),
            hidden: user => !user.twofactor_required,
          },
          {
            onClick: async user => {
              await changeTwoFactorAuth({ userId: user.id ?? '', twofa: false });
              enqueueSnackbar(t('two_factor_authentication_disabled'), {
                key: 'twoFactorAuth',
                variant: 'success',
              });
            },
            icon: <LockClosed />,
            label: t('force_remove_2factor_auth'),
            hidden: user => user.twofactor_required === true,
          },
          {
            onClick: user => {
              setUserToChangeRole(user);
            },
            icon: <User />,
            label: t('change_role'),
          },
          {
            icon: <DeleteDustbin />,
            label: t('remove'),
            onClick: user => {
              setUserToDelete(user);
            },
            color: 'reds.500',
          },
        ]}
        columns={columns}
        data={data?.data.result ?? []}
        totalRowCount={data?.data.metadata?.total ?? 0}
        isLoading={isLoading}
        state={tableState}
        enablePagination
        enableSearch
        searchPlaceholder={t('search_users')}
        emptyState={
          <EmptyState icon={<UserThree />} title={t('no_data_yet')} action={<InviteUser />} />
        }
        rowIdKey="email"
      >
        <InviteUser />
      </Table>
      {userToChangeRole ? (
        <UserRoleDialog
          user={userToChangeRole}
          onClose={() => {
            setUserToChangeRole(null);
          }}
        />
      ) : null}
      {userToDelete ? (
        <ConfirmationDialog
          title={t('user_delete')}
          description={
            <Trans
              i18nKey="user_delete_confirmation_description"
              values={{ user: userToDelete.email }}
            />
          }
          confirmColor="error"
          onClose={() => setUserToDelete(null)}
          onConfirm={handleConfirmDelete}
        />
      ) : null}
    </>
  );
}
